import me from './genesys/me.js';
import users from './genesys/users.js';

export default {
  namespaced: true,
  modules: {
    me,
    users,
  },
};
