<template>
  <v-app>
    <AppBar @drawer="drawer = !drawer" />
    <NavigationDrawer v-model="drawer" />
    <v-main>
      <router-view></router-view>
    </v-main>
    <NotificationBar />
  </v-app>
</template>

<script>
import AppBar from '@/components/AppBar.vue';
import NavigationDrawer from '@/components/NavigationDrawer.vue';
import NotificationBar from '@/components/NotificationBar.vue';

export default {
  name: 'App',
  components: {
    AppBar,
    NotificationBar,
    NavigationDrawer,
  },
  data() {
    return {
      drawer: null,
    };
  },
};
</script>

<style>
audio {
  display: block;
  width: 100%;
}
.pointer {
  cursor: pointer;
}
.nowrap {
  white-space: nowrap;
}
</style>
