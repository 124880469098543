const redirect = () => {
  localStorage.setItem('pathname', window.location.pathname);
  const parameters = {
    response_type: 'code',
    client_id: process.env.VUE_APP_CLIENT_ID,
    redirect_uri: process.env.VUE_APP_REDIRECT_URI,
  };
  const queryString = Object.keys(parameters)
    .map((key) => key + '=' + parameters[key])
    .join('&');

  window.location.replace(`https://login.mypurecloud.de/oauth/authorize?${queryString}`);
};

export default redirect;
