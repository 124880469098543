import Vue from 'vue';
import VueRouter from 'vue-router';
import CallbackView from '@/views/CallbackView.vue';
import HomeView from '@/views/HomeView.vue';
import store from '@/store';
import redirect from '@/lib/redirect';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: { requiresAuth: true },
  },
  {
    path: '/callback',
    name: 'Callback',
    component: CallbackView,
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import(/* webpackChunkName: "users" */ '@/views/UsersView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/tasks/set-wrap-up-codes',
    name: 'SetWrapUpCodes',
    component: () => import(/* webpackChunkName: "setwrapupcodes" */ '@/views/SetWrapUpCodesView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/tasks/add-users-to-groups',
    name: 'AddUsersToGroups',
    component: () => import(/* webpackChunkName: "adduserstogroups" */ '@/views/AddUsersToGroupsView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/tasks/add-users-to-queues',
    name: 'AddUsersToQueues',
    component: () => import(/* webpackChunkName: "adduserstoqueues" */ '@/views/AddUsersToQueuesView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/tasks/add-users-to-roles',
    name: 'AddUsersToRoles',
    component: () => import(/* webpackChunkName: "adduserstoroles" */ '@/views/AddUsersToRolesView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/tasks/add-users-to-skills',
    name: 'AddUsersToSkills',
    component: () => import(/* webpackChunkName: "adduserstoskills" */ '@/views/AddUsersToSkillsView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/tasks/add-users-to-languages',
    name: 'AddUsersToLanguages',
    component: () => import(/* webpackChunkName: "adduserstolanguages" */ '@/views/AddUsersToLanguagesView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/backup',
    name: 'Backup',
    component: () => import(/* webpackChunkName: "backup" */ '@/views/BackupView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/backup-roles',
    name: 'BackupRoles',
    component: () => import(/* webpackChunkName: "backup" */ '@/views/ExportRolesView.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/import',
    name: 'Import',
    component: () => import(/* webpackChunkName: "backup" */ '@/views/ImportView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/add-extension-to-user',
    name: 'AddExtensionToUser',
    component: () => import(/* webpackChunkName: "export" */ '@/views/AddExtensionToUserView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/import-roles',
    name: 'ImportRoles',
    component: () => import(/* webpackChunkName: "export" */ '@/views/ImportRolesView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: () => import(/* webpackChunkName: "statistics" */ '@/views/StatisticsView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/prompts',
    name: 'Prompts',
    component: () => import(/* webpackChunkName: "prompts" */ '@/views/PromptsView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/prompts/add',
    name: 'PromptAdd',
    component: () => import(/* webpackChunkName: "promptadd" */ '@/views/PromptAddView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/prompts/:id',
    name: 'PromptDetail',
    component: () => import(/* webpackChunkName: "promptdetail" */ '@/views/PromptDetailView.vue'),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/contactlists',
    name: 'ContactLists',
    component: () => import(/* webpackChunkName: "contactlists" */ '@/views/ContactListsView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/contactlists/:id',
    name: 'ContactListDetail',
    component: () => import(/* webpackChunkName: "contactlistdetail" */ '@/views/ContactListDetailView.vue'),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/jobresults',
    name: 'JobResults',
    component: () => import(/* webpackChunkName: "jobresults" */ '@/views/JobResultsView.vue'),
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = store.getters['auth/loggedIn'];
  if (to.meta.requiresAuth && !loggedIn) redirect();
  else next();
});

export default router;
