export default {
  namespaced: true,
  state: {
    options: null,
    filter: {
      status: [],
      division: [],
      search: '',
    },
  },
  mutations: {
    SET_OPTIONS(state, options) {
      state.options = options;
    },
    SET_FILTER(state, { prop, value }) {
      state.filter[prop] = value;
    },
  },
  actions: {
    updateOptions({ commit }, options) {
      commit('SET_OPTIONS', options);
    },
    updateFilter({ commit }, { prop, value }) {
      commit('SET_FILTER', { prop, value });
    },
  },
};
