import Vue from 'vue';
import Vuex from 'vuex';
import auth from '@/store/modules/auth.js';
import notifications from '@/store/modules/notifications.js';
import genesys from '@/store/modules/genesys.js';
import prompts from '@/store/modules/prompts.js';
import counter from '@/store/modules/counter.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    counter,
    notifications,
    genesys,
    prompts,
  },
  strict: process.env.NODE_ENV === 'development',
});
