export default {
  namespaced: true,
  state: {
    voices: [],
    options: {
      itemsPerPage: 10,
      page: 1,
    },
    search: null,
  },
  mutations: {
    SET_VOICES(state, voices) {
      state.voices = voices;
    },
    SET_OPTIONS(state, options) {
      state.options = options;
    },
    SET_SEARCH(state, search) {
      state.search = search;
    },
  },
  actions: {
    async getAllVoices({ commit, state }, speech) {
      if (state.voices.length > 0) return;
      const response = await speech.getVoices();
      commit('SET_VOICES', response.privVoices);
    },
  },
  getters: {
    voices: (state) => (locale) => {
      return state.voices.filter((v) => v.privLocale.toLowerCase() === locale);
    },
  },
};
