export default {
  state: {
    countedUsers: '0',
  },
  getters: {
    countedUsers: (state) => {
      return state.countedUsers;
    },
  },
  mutations: {
    updatecountedUsers(state, payload) {
      state.countedUsers = payload;
    },
  },
  actions: {
    updatecountedUsers({ commit }, payload) {
      commit('updatecountedUsers', payload);
    },
  },
};
