import axios from 'axios';
import store from '@/store';
import rateLimit from 'axios-rate-limit';

const genesys = axios.create({
  baseURL: 'https://api.mypurecloud.de',
});

genesys.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      store.dispatch('auth/logout');
    }
    return Promise.reject(error);
  }
);

const limit = rateLimit(genesys, { maxRPS: 5 });

export default limit;
