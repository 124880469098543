import GenesysService from '@/services/GenesysService.js';

export default {
  namespaced: true,
  state: {
    me: null,
  },
  mutations: {
    SET_ME(state, me) {
      state.me = me;
    },
  },
  actions: {
    async getMe({ commit }) {
      try {
        const response = await GenesysService.getMe('organization%2Cauthorization');
        delete response.data.authorization.permissionPolicies;
        commit('SET_ME', response.data);
      } catch (error) {
        console.error(error.message);
      }
    },
  },
  getters: {
    divisions: (state) => (prefix) => {
      const permission = state.me ? state.me.authorization.permissions.find((p) => p.startsWith(prefix)) : '';
      return permission.slice(prefix.length).split(',');
    },
    checkPermissions: (state) => (permissions) => {
      if (!state.me) return false;
      return permissions.every((permission) => !!state.me.authorization.permissions.find((item) => item.startsWith(permission)));
    },
  },
};
