<template>
  <v-navigation-drawer :clipped="$vuetify.breakpoint.lgAndUp" app v-bind="$attrs" v-on="$listeners" v-if="loggedIn">
    <MeList />
    <v-divider></v-divider>
    <v-list dense>
      <v-list-item-group color="primary">
        <v-list-item v-for="link in menu.links" :key="link.name" :to="link.to" link exact :disabled="!displayLink(link)">
          <v-list-item-icon>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ link.name }}</v-list-item-title>
        </v-list-item>
        <v-list-group v-for="group in menu.groups" :key="group.name" :prepend-icon="group.icon" no-action>
          <template v-slot:activator>
            <v-list-item-title>{{ group.name }}</v-list-item-title>
          </template>
          <v-list-item v-for="link in group.links" :key="link.name" :to="link.to" :disabled="!displayLink(link)">
            <v-list-item-title>
              {{ link.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import MeList from '@/components/MeList.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'NavigationDrawer',
  components: {
    MeList,
  },
  data() {
    return {
      menu: {
        links: [
          { name: 'Home', to: { name: 'Home' }, icon: 'mdi-home-variant-outline' },
          { name: 'Users', to: { name: 'Users' }, icon: 'mdi-account-multiple', permissions: ['directory:user:view'] },
          { name: 'Prompts', to: { name: 'Prompts' }, icon: 'mdi-account-tie-voice', permissions: ['architect:userPrompt:view'] },
          {
            name: 'Contact Lists',
            to: { name: 'ContactLists' },
            icon: 'mdi-format-list-bulleted',
            permissions: ['outbound:contactList:view'],
          },
          { name: 'Backup', to: { name: 'Backup' }, icon: 'mdi-cloud-download-outline' },
          {
            name: 'Import',
            to: { name: 'Import' },
            icon: 'mdi-cloud-upload-outline',
          },
        ],
        groups: [
          {
            name: 'Tasks',
            icon: 'mdi-format-list-checks',
            links: [
              {
                name: 'Set Wrap-Up Codes',
                to: { name: 'SetWrapUpCodes' },
                permissions: ['routing:wrapupCode:view', 'routing:queue:view', 'routing:queue:edit'],
              },
              { name: 'Add Users To Groups', to: { name: 'AddUsersToGroups' }, permissions: ['directory:user:view'] },
              {
                name: 'Add Users To Queues',
                to: { name: 'AddUsersToQueues' },
                permissions: ['routing:queue:view', 'routing:queue:edit', 'routing:queueMember:manage'],
              },
              {
                name: 'Add Users To Roles',
                to: { name: 'AddUsersToRoles' },
                permissions: [
                  'directory:user:view',
                  'authorization:role:view',
                  'authorization:grant:add',
                  'authorization:grant:delete',
                ],
              },
              {
                name: 'Add Users To Skills',
                to: { name: 'AddUsersToSkills' },
                permissions: ['directory:user:view', 'routing:skill:assign'],
              },
              {
                name: 'Add Users To Languages',
                to: { name: 'AddUsersToLanguages' },
                permissions: ['directory:user:view', 'routing:language:assign'],
              },
              {
                name: 'Add Extension To User',
                to: { name: 'AddExtensionToUser' },
                permissions: ['directory:user:view', 'directory:user:edit'],
              },
            ],
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['loggedIn']),
    ...mapGetters('genesys/me', ['checkPermissions']),
  },
  methods: {
    displayLink(link) {
      return link.permissions ? this.checkPermissions(link.permissions) : true;
    },
  },
};
</script>
